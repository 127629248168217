
















import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import Competence from '@/components/hszg/Competence.vue'
import { CompetenceImprovement } from '@/store/kode/types'

@Component({
  components: { Competence }
})
// Shows the selected competences (job family + base competence or single competences) for advancement (talent)
// @group KODE
export default class SelectedCompetences extends Vue {
  // selected competences and job family (from backend)
  @Prop({ required: true })
  improvementData: CompetenceImprovement

  // if the competence pdfs are downloadable
  @Prop()
  downloadable: boolean
}
