





















































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { JobFinderType } from '@/store/kode/types'
import MatchingResultOverview from '@/components/search-results/MatchingResultOverview.vue'

@Component({
  components: { MatchingResultOverview }
})
// Container component for the tabs on the job finder page
// @group KODE
export default class JobFinderResults extends Vue {
  // result from the job finder (from backend)
  @Prop()
  private jobFinderResult: Array<JobFinderType>

  // cross competences (from backend)
  @Prop()
  private crossCompetenceResult: Array<JobFinderType>

  // to hide the cross competences (used in the dream job page)
  @Prop()
  private hideCrossCompetence: boolean
}
