













































































































































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import JobSearchResult from '@/components/search-results/JobSearchResult.vue'
import { Action, Getter } from 'vuex-class'
import JobFamily from '@/components/hszg/JobFamily.vue'
import { SimpleServerResponse, UserProgressState } from '@/store/types'
import { GET_PROFILE_KODE_STATUS, UPDATE_STUDENT_STATE } from '@/store/user/actions.type'
import TargetCompetenceProfileResult from '@/components/kode/TargetCompetenceProfileResult.vue'
import {
  GET_COMPETENCES_TO_IMPROVE_OWN,
  GET_KODE_BRIDGE_RESULT,
  SAVE_COMPETENCES_TO_IMPROVE_OWN
} from '@/store/kode/actions'
import { CompetenceDetail, CompetenceImprovement, JobFinderType, KodeStatusDTO } from '@/store/kode/types'
import InfoBox from '@/components/hszg/InfoBox.vue'
import Competence from '@/components/hszg/Competence.vue'
import { competenceEnumFromId } from '@/common/globals'
import MatchingResultOverview from '@/components/search-results/MatchingResultOverview.vue'
import JobFinderResultTabs from '@/components/kode/JobFinderResultTabs.vue'
import SelectedCompetences from '@/components/kode/SelectedCompetences.vue'

@Component({
  components: { SelectedCompetences, JobFinderResultTabs, MatchingResultOverview, Competence, InfoBox, TargetCompetenceProfileResult, JobFamily, JobSearchResult }
})
// Displays the job finder list (used in job finder and dream job page)
// @group KODE
export default class JobFinderList extends Vue {
    @Action(GET_PROFILE_KODE_STATUS)
    public getProfileKodeStatus: () => Promise<KodeStatusDTO>

    @Getter('userStateGetter') userStateGetter: UserProgressState

    @Action(UPDATE_STUDENT_STATE)
    public updateStudentState: (data: UserProgressState) => Promise<void>

    @Action(GET_KODE_BRIDGE_RESULT)
    public getKodeBridgeResult: (id: string) => Promise<SimpleServerResponse>

    @Action(GET_COMPETENCES_TO_IMPROVE_OWN)
    public getCompetencesToImproveOwn: () => Promise<SimpleServerResponse>

    @Action(SAVE_COMPETENCES_TO_IMPROVE_OWN)
    public saveCompetencesToImproveOwn: (improvement: CompetenceImprovement) => Promise<SimpleServerResponse>

    // result from the job finder (from backend)
    @Prop()
    jobFinderResult: Array<JobFinderType> = []

    // cross competences (from backend)
    @Prop()
    crossCompetenceResult: Array<JobFinderType> = []

    // if backend is loading
    @Prop()
    loading: boolean

    // a hint that is displayed while loading
    @Prop({ required: true })
    loadingHint: string

    // to hide the cross competences (used in the dream job page)
    @Prop()
    hideCrossCompetence: boolean

    // if the backend request failed
    @Prop()
    requestFailed = false

    get jobFinderResultState (): Array<JobFinderType> {
      return this.jobFinderResult
    }

    get crossCompetenceResultState (): Array<JobFinderType> {
      return this.crossCompetenceResult
    }

    private jobFamilySelected = false
    private modalJobFamily: { id: string; title: string; type: string } = { id: '', title: '', type: '' }

    error = {}
    kodeStatus = ''

    kodeBridge: {
      loading: boolean;
      requestFailed: boolean;
      error: Error | null;
      result: CompetenceDetail[];
    } = {
      loading: true,
      requestFailed: false,
      error: null,
      result: []
    }

    improvementData: CompetenceImprovement = {}

    selectedCompetences = []
    selectedBaseCompetence = ''

    calculationBasis = 'average'

    validationState: boolean | null = null
    invalidFeedback = ''

    submitHeadline = ''

    get calculationBasisOptions (): { text: string; value: string }[] {
      return [
        { text: this.$i18n.t('job-finder.average').toString(), value: 'average' },
        { text: this.$i18n.t('job-finder.favorable').toString(), value: 'favorable' },
        { text: this.$i18n.t('job-finder.unfavorable').toString(), value: 'unfavorable' }
      ]
    }

    mounted (): void {
      this.loadKodeStatusAndCompetencesForImprovement()
    }

    loadKodeStatusAndCompetencesForImprovement (): void {
      this.getProfileKodeStatus().then(data => {
        this.kodeStatus = data.kodeStatus

        this.getCompetencesToImproveOwn().then(data => {
          if (data.content) {
            this.improvementData = data.content
            if (this.improvementData.jobFamilyId) this.jobFamilySelected = true
          }
        })
      })
    }

    openModal (id: string, type: string): void {
      this.clearSelection()
      this.validationState = null
      let jobFamily
      if (type === 'CROSS_COMPETENCES') {
        jobFamily = this.crossCompetenceResultState.filter(ele => ele.targetCompetenceProfile === id)[0]
      } else {
        jobFamily = this.jobFinderResultState.filter(ele => ele.targetCompetenceProfile === id)[0]
      }
      this.modalJobFamily = { id: jobFamily.targetCompetenceProfile, title: jobFamily.jobTitle, type: jobFamily.competenceProfileType }

      this.kodeBridge.loading = true
      this.kodeBridge.requestFailed = false
      this.getKodeBridgeResult(jobFamily.targetCompetenceProfile).then((data: SimpleServerResponse) => {
        this.kodeBridge.result = JSON.parse(data.content.result).competenceDetails
        this.kodeBridge.result = this.kodeBridge.result.filter(el => !(el.kodeAverageMin100 + ((el.kodeAverageMax100 - el.kodeAverageMin100) / 2) > el.corridorMin100))
      }).catch(error => {
        this.kodeBridge.error = error
        this.kodeBridge.requestFailed = true
      }).finally(() => {
        this.kodeBridge.loading = false
      })

      this.$bvModal.show('competence-advancement-modal')
    }

    closeModal (): void {
      this.$bvModal.hide('competence-advancement-modal')
    }

    // select job family and competences for advancement
    selectJobFamily (): void {
      const improvement: CompetenceImprovement = {}
      let improvementReady = false

      // there is no need to advance in any competences
      if (this.kodeBridge.result.length === 0) {
        improvement.jobFamilyId = this.modalJobFamily.id
        improvement.jobFamily = this.modalJobFamily.title
        improvementReady = true
        this.submitHeadline = this.$i18n.t('info.save-success', { what: this.$i18n.tc('job-finder.JOB_FAMILY', 1) }).toString()
      } else {
        this.validateInput()
        if (this.validationState === null) {
          // only 1 or 2 single competences ..
          if (this.selectedCompetences.length > 0 && this.selectedCompetences.length <= 2) {
            improvement.jobFamilyId = this.modalJobFamily.id
            improvement.jobFamily = this.modalJobFamily.title
            improvement.competencesToImprove = this.selectedCompetences.map(comp => competenceEnumFromId(comp))
            improvementReady = true
          // or one base competence
          } else if (Object.keys((this.$i18n.t('kode.base-competences'))).some(el => el === this.selectedBaseCompetence)) {
            improvement.jobFamilyId = this.modalJobFamily.id
            improvement.jobFamily = this.modalJobFamily.title
            improvement.baseCompetenceToImprove = this.selectedBaseCompetence
            improvementReady = true
          }
          this.submitHeadline = this.$i18n.t('info.save-success', { what: this.$i18n.t('job-finder.competences-for-advancement') }).toString()
        }
      }

      if (improvementReady) {
        this.saveCompetencesToImproveOwn(improvement).then(data => {
          this.jobFamilySelected = data.requestStatus === 'SUCCESS'
          this.loadKodeStatusAndCompetencesForImprovement()
          this.$bvModal.hide('competence-advancement-modal')
          this.$root.$emit('alert', this.$i18n.t('info.save-success', { what: this.$i18n.t('data') }), '')
        })
      }
    }

    validateInput (): void {
      this.validationState = null
      if (this.selectedCompetences.length === 0 &&
        !(Object.keys((this.$i18n.t('kode.base-competences'))).some(el => el === this.selectedBaseCompetence))) {
        this.invalidFeedback = this.$i18n.t('job-finder.please-select-at-least-one-competence').toString()
        this.validationState = false
      } else if (this.selectedCompetences.length > 2) {
        this.invalidFeedback = this.$i18n.t('job-finder.please-select-max-two-competences').toString()
        this.validationState = false
      }
    }

    clearSelection (): void {
      this.selectedCompetences = []
      this.selectedBaseCompetence = ''
    }

    get weakBaseCompetence (): Array<string> {
      if (this.kodeBridge.result && this.kodeBridge.result.length > 3) {
        let mainCompetences: string[] = []
        this.kodeBridge.result.forEach(comp => {
          const shortCompetences = Object.values((this.$i18n.t('all-competences'))).filter(c => c.id === comp.competenceId)[0].shortCompetences
          // weighting of the base competences (2/3 to 1/3 for first and second base competence or 100% for single base competences)
          mainCompetences = [...mainCompetences, ...shortCompetences[0], ...shortCompetences[0], ...shortCompetences[shortCompetences.length === 2 ? 1 : 0]]
        })

        // get count of base competence appearance
        const counts: Array<{ competence: string; count: number }> = [
          { competence: 'P', count: mainCompetences.filter(c => c === 'P').length },
          { competence: 'A', count: mainCompetences.filter(c => c === 'A').length },
          { competence: 'F', count: mainCompetences.filter(c => c === 'F').length },
          { competence: 'S', count: mainCompetences.filter(c => c === 'S').length }
        ]
        // sort by count
        counts.sort((a: { competence: string; count: number }, b: { competence: string; count: number }) => (a.count > b.count ? -1 : 1))

        // return all competences with highest appearance (normally just one)
        const highest = counts[0].count
        const selectableBaseCompetences = [...counts.filter(c => c.count === highest)]
        return selectableBaseCompetences.map(el => el.competence)
      } else {
        return []
      }
    }
}
