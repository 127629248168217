




























import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { Action } from 'vuex-class'
import { GET_PROFILE_KODE_STATUS } from '@/store/user/actions.type'
import InfoBox from '@/components/hszg/InfoBox.vue'
import JobFinderList from '@/components/kode/JobFinderList.vue'
import { GET_JOB_FINDER_RESULTS } from '@/store/kode/actions'
import { JobFinderType, KodeStatusDTO } from '@/store/kode/types'

  @Component({
    components: { JobFinderList, InfoBox }
  })
export default class JobFinder extends Vue {
    @Action(GET_PROFILE_KODE_STATUS)
    public getProfileKodeStatus: () => Promise<KodeStatusDTO>

    @Action(GET_JOB_FINDER_RESULTS)
    public getJobFinderResults: () => Promise<Array<JobFinderType>>

    loading = true
    requestFailed = false
    error = false
    kodeStatus = 'FINISH'

    jobFinderResult: Array<JobFinderType> = []
    crossCompetenceResult: Array<JobFinderType> = []

    created (): void {
      this.loadKodeStatusAndCompetencesForImprovement()
      this.loadData()
    }

    loadKodeStatusAndCompetencesForImprovement (): void {
      this.getProfileKodeStatus().then(data => {
        this.kodeStatus = data.kodeStatus
      })
    }

    private loadData (): void {
      this.loading = true
      this.requestFailed = false
      this.getJobFinderResults().then(data => {
        this.jobFinderResult = data.filter(entry => {
          return entry.competenceProfileType === 'JOB_FAMILY'
        })
        this.crossCompetenceResult = data.filter(entry => {
          return entry.competenceProfileType === 'CROSS_COMPETENCES'
        })
      }).catch(error => {
        this.error = error
        this.requestFailed = true
      }).finally(() => {
        this.loading = false
      })
    }
}
